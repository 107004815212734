<template>

    <h1>Mes etablissements <span class="number">{{ etablissements.length }}</span></h1>
    <div class="row" v-if="etablissements.length > 0">
        <div class="col-12 col-md-6" v-for="etablissement in etablissements" :key="etablissement.id">
            <div class="card text-center">
                <div class="card-body">
                    <h2>{{ etablissement.titre }}</h2>
                    <router-link  :to="{ name: 'etablissement', params: { uuid: etablissement.uuid }}" title="Modifier l'établissement"><i class="fa fa-edit fa-2x text-pink"></i></router-link>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>Etablissements</h5>
                </div>
                <div class="ibox-content" v-if="etablissements.length > 0">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover dataTables-example" >
                            <thead>
                                <tr>
                                    <th>libelle</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="gradeX" v-for="etablissement in etablissements" :key="etablissement.id">
                                    <td>{{ etablissement.titre }}</td>
                                    <td class="center">                                  
                                    <router-link  :to="{ name: 'etablissement', params: { uuid: etablissement.uuid }}"><i class="fa fa-edit text-navy"></i></router-link>                                   
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>


export default {
  name: 'Etablissements',
  data() {
    return {  
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {      
      this.$store.dispatch('getEtablissements');
    }
  },
  computed: {
    etablissements () {
      return this.$store.state.etablissements.etablissements
    },
  },
  components: {
  },
};
</script>